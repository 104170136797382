<template>
  <tr class="border-b border-purple-25 border-dashed">
    <td class="px-4 py-3">
      <div class="flex items-center text-sm">
        <div>
          <p class="font-semibold text-black">{{ post.data.title }}</p>
          <p class="text-xs text-gray-600">Tag | Tag | Tag</p>
        </div>
      </div>
    </td>
    <td class="px-4 py-3 text-ms font-semibold">
      <div>
        <p class="font-semibold text-black">21 Jan 2020</p>
        <p class="text-xs text-gray-600">by James Doe</p>
      </div>
    </td>
    <td class="px-4 py-3 text-ms font-semibold">
      <div>
        <p class="font-semibold text-black">256 views</p>
        <p class="text-xs text-gray-600">92 comments</p>
      </div>
    </td>
    <td class="px-4 py-3 text-xs">
      <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">Acceptable</span>
      <!-- <span class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-gray-100 rounded-sm"> Pending </span> -->
      <!-- <span class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm"> Nnacceptable </span> -->
    </td>
    <td class="px-4 py-3 text-sm">
      <span class="flex">

        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-700 mx-2" viewBox="0 0 20 20" fill="currentColor">
          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"/>
        </svg>

        <a 
          href="#"
          v-on:click="deleteRow(index)"
        >
          <trash-icon 
            size="44" stroke-width="2"
            class="h-5 w-5 text-red-700 hover:text-red-500" 
          />
        </a>
      </span>
    </td>
  </tr>
</template>

<script>
import { TrashIcon } from "vue-tabler-icons";

export default {
  name: "PostListComponent",
  components: {
    TrashIcon
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    deleteRow(index) {
      this.$confirm(
        {
          message: `Are you sure you want to delete this record? Clicking "Yes" will 🔥 delete the record.`,
          button: {
            no: 'Cancel',
            yes: 'Yes'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm 
          */
          callback: confirm => {
            if (confirm) {
              alert('Index ' + index + ' will be deleted.')
            }
          }
        }
      )
    }
  }
}
</script>
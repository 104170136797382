<template>
  <div class="px-6 py-8">
    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
      <div class="mb-4 flex items-center justify-between">
        <div>
          <h3 class="text-xl font-bold text-gray-900 mb-2">Blog Posts</h3>
          <span class="text-base font-normal text-gray-500">This is a list of posts.</span>
        </div>
        <div class="flex-shrink-0">
          <button 
            @click="show"
            class='font-medium text-white bg-purple-600 btn-sm hover:bg-purple-500 group' 
          >
            Modal
            <plus-icon 
              size="44" stroke-width="2"
              class="w-5 h-5 ml-3 group-hover:animate-horizontal-bounce" 
            />
          </button>
            <modal name="post-modal">
              Will be using this to add a new post
            </modal>

          <button 
            @click="togglePost"
            type="button"
            class="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2"
          >Create Post</button>
        </div>
      </div>
      <!-- Add new post form -->
      <div 
        v-if="toggleCreate"
        class="border-2 border-gray-100 rounded-md"
      >
        <form 
          @submit.prevent="createPost"
          class="m-5"
        >
          <!-- 
          <div v-if="errorMsg" class=" text-red-500">
            <span class="text-sm">{{ errorMsg }}</span>
          </div> 
          -->
          <input 
            v-model="post.title"
            type="text"
            class="mt-4 border-0 px-3 py-3 text-gray-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring focus:bg-white w-full ease-linear transition-all duration-150"
            placeholder="Title" />
          <textarea 
            v-model="post.post"
            type="textarea"
            rows="6"
            required
            class="mt-4 mb-4 border-0 px-3 py-3 placeholder-blueGray-300 text-geay-600 bg-gray-100 rounded text-sm shadow focus:outline-none focus:ring focus:bg-white w-full ease-linear transition-all duration-150"
            placeholder="Write your post"
          ></textarea>
          <div class="text-right md:space-x-3 md:block flex flex-col-reverse">
            <button
              type="submit"
              class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-200"
            >Save</button>
            <button
              type="reset"
              class="mb-2 md:mb-0 bg-purple-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-purple-500 ml-1"
            >Reset</button>
          </div>
        </form>
      </div>
      <!-- Data table -->
      <div class="flex flex-col mt-8">
        <div class="overflow-x-auto rounded-lg">
          <div class="align-middle inline-block min-w-full">
            <div class="shadow overflow-hidden sm:rounded-lg">
              <table class="min-w-full divide-y divide-purple-100">
                <thead class="bg-purple-25">
                  <tr>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Title
                    </th>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Created
                    </th>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Engagement
                    </th>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <!-- Post List -->
                  <post-list
                    v-for="(post, idx) in posts" :key="post.id"
                    v-bind="{ post: post, index: idx }"
                  ></post-list>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostList from '@/components/backend/PostList.vue'
import { PlusIcon } from "vue-tabler-icons";

export default {
  name: 'PostsView',
  data() {
    return {
      toggleCreate: false,
      post: {
        title: undefined,
        post: undefined
      }
    }
  },
  components: { 
    PostList,
    PlusIcon 
  },
  methods: {
    togglePost() {
      this.toggleCreate = !this.toggleCreate
    },
    createPost() {
      // console.log(this.post.title)
      // console.log(this.post.post)
      this.$store.dispatch('createPost', this.post)
      // reset post
      this.resetPostData()
      this.togglePost()
    },
    resetPostData() {
      this.post = {
        title: undefined,
        post: undefined
      }
    },
    /* Show modal */
    show() {
      this.$modal.show('post-modal')
    },
    /* Hide modal */
    hide() {
      this.$modal.hide('post-modal')
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    posts() {
      return this.$store.state.posts
    }
  }
}
</script>